//Core
import { Fragment } from "react";
//Styles
import Styles from "./styles.module.scss";
//Icons
import tel from "../../../assets/icons/tel.svg";
import people from "../../../assets/icons/people.svg";
import person from "../../../assets/icons/person.svg";
import receive from "../../../assets/icons/receive.svg";
//Traslation
import { useTranslation } from 'react-i18next'
import { dataForParnetHintModalArabic, dataForParnetHintModalEnglish } from "./locale";


const PreConsultationSection = () => {

  const { t, i18n } = useTranslation(['common'])

  const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

  return (
    <>
        <Fragment dir={textDirection}>
          <div className={Styles.section__container}>
            <div className={Styles.section}>
            {(i18n.language === 'en' ? dataForParnetHintModalEnglish : dataForParnetHintModalArabic)?.map(item => <div className={Styles.section__item} key={item?.id}>
              <div className={Styles.section__item__label}>
                <div>{item?.id}</div>
                <h5>{item?.label}</h5>
              </div>
              <span>{item?.description}</span>
            </div>)}
            </div>
          </div>
        </Fragment>
    </>
  );
};

const Circle = () => {
  return (
    <div className={Styles.circleContainer}>
      {Array.from({ length: 4 }).map((_) => (
        <div className={Styles.circle}></div>
      ))}
    </div>
  );
};

export default PreConsultationSection;
