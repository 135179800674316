//Styles
import Styles from './styles.module.scss'
//Traslation
import { useTranslation } from 'react-i18next'
//Images
import assistent_en from '../../assets/images/doctors.png'
import assistent_ar from '../../assets/images/doctors_ar.png'

const ChooseSpecialist = () => {
	const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

	return (
		<section className={Styles.wrapper}>
			<div
				className={Styles.image}
				style={{ backgroundImage: textDirection === 'ltr' ? `url(${assistent_en})` : `url(${assistent_ar})` }}
			/>

			<div dir={textDirection} className={Styles.wrapper_content}>
				<div className={Styles.step}>2</div>
				<h1 className={Styles.title}>{t('specialist.chooseSpecialist')}</h1>
				<p className={Styles.description}>{t('specialist.chooseSpecialistDescr')}</p>
			</div>
		</section>
	)
}

export default ChooseSpecialist
