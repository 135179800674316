//Styles
import Styles from './styles.module.scss'
//Traslation
import { useTranslation } from 'react-i18next'
//Core
import { useEffect, useState } from 'react'

const Mission = () => {

	const [videoId, setVideoId] = useState('https://youtu.be/nvQy6xYI2OA')

	const playVideo = (e) => {
		let imageOverlay = document.getElementById('play-video')
		imageOverlay.style.display = 'none'

		let video = document.getElementById('video')
		video.style.cssText += 'display: block; height: 100%; opacity: 1'

        video.contentWindow.postMessage( '{"event":"command", "func":"playVideo", "args":""}', '*');
		e.preventDefault()
	}
	const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

	useEffect(() => {
		if (i18n.language === 'en') {
			setVideoId('nvQy6xYI2OA')
		} else {
			setVideoId('ZSSo74-0QLw')
		}
	}, [i18n.language])
	return (
		<section className={Styles.wrapper}>
			<div className={Styles.video_wrapper}>
				<div id="play-video" className={Styles.video_overlay} onClick={playVideo} />
				<iframe
				src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&version=3&playerapiid=ytplayer&rel=0&modestbranding=0&showinfo=0&controls=1`}
                    id="video"
                    title='Numuw Platform'
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>

			<div className={Styles.video_descr} dir={textDirection}>
				<p className={`${Styles.mission} ${textDirection === 'rtl' ? Styles.mission_ar : null}`}>
					“{t('mission.mission')}”
				</p>

				<div className={Styles.numuw}>
					<div className={Styles.fouder}>
						<p className={Styles.name}>{t('mission.founderName')}</p>
						<p>{t('mission.founder')}</p>
					</div>

					<div className={Styles.logo} />
				</div>
			</div>
		</section>
	)
}

export default Mission
