//Core
import { useState } from "react";
//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
//Components
import PreConsultationPopup from "../PreConsultationPopup";

const Footer = () => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  const [isOpenPreConsultation, setIsOpenPreConsultation] = useState(false);

  const handlePreOpenConsultation = () => setIsOpenPreConsultation(true);
  const handlePreCloseConsultation = () => setIsOpenPreConsultation(false);


  return (
    <>
      <footer>
        <div className={Styles.first_block}>
          <div className={Styles.logo} />
          <p dir={textDirection}>{t("footer.description")}</p>
          <div className={Styles.second_block__second_line_container}>
            <a
              className={Styles.private_police}
              href="/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>

            <a
              className={Styles.private_police}
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
        <div className={Styles.third_block}>
          <div className={Styles.footer_buttons}>
            <div dir={textDirection}>
              <button
                onClick={handlePreOpenConsultation}
                className={Styles.consult_btn}
              >
                {t("buttons.freeConsultation")}
              </button>
            </div>
          </div>
          <ul className={Styles.footer_socials}>
            <a
              href="https://www.instagram.com/numuwhub/"
              target="_blank"
              rel="noreferrer"
            >
              <li className={Styles.social_insta}></li>
            </a>
            <a
              href="https://www.linkedin.com/company/numuw/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
            >
              <li className={Styles.social_in}></li>
            </a>
          </ul>
          <div className={Styles.second_block__second_line_container_mobile}>
            <a
              className={Styles.private_police}
              href="/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>

            <a
              className={Styles.private_police}
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </footer>
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={isOpenPreConsultation}
      />
    </>
  );
};

export default Footer;
