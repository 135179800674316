//Styles
import Styles from './styles.module.scss'
//Traslation
import { useTranslation } from 'react-i18next'
//Images
import assistent_en from '../../assets/images/personal_assistent.png'
import assistent_ar from '../../assets/images/personal_assistent_ar.png'

const PersonalAssistent = () => {
	const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

	return (
		<section className={Styles.wrapper} id={'advisors'}>
			<div
				className={Styles.image}
				style={{ backgroundImage: textDirection === 'ltr' ? `url(${assistent_en})` : `url(${assistent_ar})` }}
			/>

			<div dir={textDirection} className={Styles.wrapper_content}>
				<h1 className={Styles.title}>{t('personalAssistent.guidedJourney')}</h1>
				<p className={Styles.description}>{t('personalAssistent.guidedJourneyDescr')}</p>

				<ul className={Styles.list_items}>
					<li>
						<span className={Styles.check_icon} />
						<p>{t('personalAssistent.guidedJourneyList.item_1')}</p>
					</li>
					<li>
						<span className={Styles.check_icon} />
						<p>{t('personalAssistent.guidedJourneyList.item_2')}</p>
					</li>
					<li>
						<span className={Styles.check_icon} />
						<p>{t('personalAssistent.guidedJourneyList.item_3')}</p>
					</li>
				</ul>
			</div>
		</section>
	)
}
export default PersonalAssistent
