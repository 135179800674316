//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";

const MeetAdvisor = () => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  return (
    <section className={Styles.wrapper} id={"therapy"}>
      <h1 className={Styles.title} dir={textDirection}>
        {t("therapy.therapyInHomeTitle")}
      </h1>
      <p className={Styles.description} dir={textDirection}>
        {t("therapy.therapyInHomeDescr")}
      </p>

      <div className={Styles.content_block}>
        <div dir={textDirection}>
          <div className={Styles.step}>1</div>
          <h1 className={`${Styles.title}`}>
            {t("therapy.meetAdvisor")}
          </h1>
          <p className={`${Styles.content_description}`}>
            {t("therapy.meetAdvisorDescr")}
          </p>
          <div className={Styles.chain}>
            <span className={Styles.question_icon} />
            <div className={Styles.connector} />
            <span className={Styles.onCall_icon} />
            <div className={Styles.connector} />
            <span className={Styles.checked_icon} />
          </div>
        </div>

        <div className={Styles.image} />
      </div>
    </section>
  );
};

export default MeetAdvisor;
