// Routing
import { Outlet } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";
// Components
import Header from "../Header";
import Footer from "../Footer";
import LanguageBlock from "../LanguageBlock";

const Main = ({ footer, header, consultButton }) => {
  return (
    <div className={Styles.container}>
      <Header header={header} consultButton={consultButton} />
      <LanguageBlock />
      <main className={Styles.main_content}>
        <Outlet />
      </main>
      <div style={{ width: "100%", background: "#ffffff" }}>
        {footer && <Footer />}
      </div>
    </div>
  );
};

export default Main;
