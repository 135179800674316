//MUI
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
//Styles
import PreConsultationSection from "./PreConsultationSection";
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
// Constants
import { QUESTIONNAIRE_LINK } from "../../constants";
import { sendConversion } from "../TagManagers/GoogleTagManager";

const PreConsultationPopup = ({ open, onClose }) => {
  const openConsultationPopup = () => {
    sendConversion("nextButtonClicked");
    window.open(`${QUESTIONNAIRE_LINK}?lng=${i18n.language}`, "_blank");
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  return (
    <div id="modal">
      <Modal open={open} onClose={onClose} sx={{ display: "flex" }}>
        <div dir={textDirection} className={Styles.wrapper}>
          <div className={Styles.header}>
            <h2 className={Styles.header__title}>
              {t("preeConsultPopup.title")}
            </h2>
            <div className={Styles.header__icon_block}>
              <div onClick={handleClose}>
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.content__sections}>
              <PreConsultationSection />
            </div>
          </div>
          <div className={Styles.footer}>
            <button
              type="button"
              className={Styles.footer__cancel}
              onClick={handleClose}
            >
              {t("preeConsultPopup.btns.cancel")}
            </button>
            <button
              type="button"
              className={Styles.footer__next}
              onClick={openConsultationPopup}
            >
              {t("preeConsultPopup.btns.next")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreConsultationPopup;
