//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from 'react-i18next'

const StartTherapy = () => {
  const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

  return (
    <section className={Styles.wrapper}>
      <div dir={textDirection} className={Styles.wrapper_content}>
        <div className={Styles.step}>3</div>
        <h1 className={Styles.title}>{t('start.startTherapy')}</h1>
        <p className={Styles.description}>{t('start.startTherapyDescr')}</p>
      </div>

      <div className={Styles.image} />
    </section>
  );
};

export default StartTherapy;
