//Styles
import { useState } from 'react'
// Mui
import { Alert, Snackbar } from '@mui/material'
// Styles
import Styles from './styles.module.scss'
// Images
import slideImg from '../../assets/images/therapist_registration_success.jpg'
//Traslation
import { useTranslation } from 'react-i18next'
// Routing
import { useNavigate } from 'react-router'
import { apiPath } from '../../constants'
//Links
import termsOfUseLink from '../../documents/terms_of_use.pdf'
import privacyPolicyLink from '../../documents/privacy_policy.pdf'

const TherapistForm = () => {
	const { t, i18n } = useTranslation(['common', 'forms'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [answer, setAnswer] = useState({})
	const navigate = useNavigate()

	const alertSettings = {
		vertical: 'bottom',
		horizontal: 'left',
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		setLoading(true)
		const name = event.target.name.value
		const email = event.target.email.value
		const info = event.target.info.value
		const receiveEmail = event.target.mailing.checked
		const selectedLang = localStorage.getItem('i18nextLng')

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				full_name: name,
				email: email,
				additional_info: info,
				language: selectedLang,
				is_agree_to_receive_email: receiveEmail,
			}),
		}
		fetch(`${apiPath}/landing/join/`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.detail === 'Ok') {
					setAnswer({ status: result.detail, text: 'Message Sent' })
					setOpen(true)
					event.target.reset()
					setLoading(false)
					navigate('/thank-you')
				}

				return result.detail
			})
			.catch((error) => {
				if (error) {
					setAnswer({ status: error, text: 'An error occurred, Please try again' })
					setOpen(true)
					event.target.reset()
					setLoading(false)
				}
			})
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpen(false)
	}

	return (
		<section className={Styles.wrapper}>
			<div className={Styles.form_wrapper} dir={textDirection}>
				<h1 className={Styles.title}>{t('common:buttons.becomeTherapist')}</h1>

				<form onSubmit={handleSubmit}>
					<div className={Styles.form_item}>
						<label className={Styles.flex_col}>{t('forms:nameLabel')}</label>

						<input
							type="text"
							name="name"
							required
							disabled={loading}
							placeholder={t('forms:namePlaceholder')}
						/>
					</div>

					<div className={Styles.form_item}>
						<label className={Styles.flex_col}>{t('forms:emailLabel')}</label>

						<input
							type="email"
							pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							name="email"
							required
							disabled={loading}
							placeholder={t('forms:emailPlaceholder')}
						/>
					</div>

					<div className={Styles.form_item}>
						<label className={Styles.flex_col}>{t('forms:therapistInfoLabel')}</label>
						<textarea
							name="info"
							placeholder={t('forms:therapistInfoPlaceholder')}
							disabled={loading}
							rows="5"
							cols="55"
						/>
					</div>

					<div className={Styles.form_item}>
						<p className={Styles.flex_ai_start}>
							<input
								type="checkbox"
								id="termsAndConditions"
								required
								name="termsAndConditions"
								disabled={loading}
							/>
							<div className={Styles.label}>
								{textDirection === 'ltr' ? (
									<div>
										I read and agree to the <p onClick={() => window.open(termsOfUseLink, "_blank")}>Terms of Use</p>
									</div>) : (<div>لقد قرأت ووافقت على <p onClick={() => window.open(termsOfUseLink, "_blank")}>شروط الاستخدام</p></div>)}
							</div>
						</p>
					</div>
					<div className={Styles.form_item}>
						<p className={Styles.flex_ai_start}>
							<input
								type="checkbox"
								id="privacyPolicy"
								required
								name="privacyPolicy"
								disabled={loading}
							/>
							<div className={Styles.label}>
								{textDirection === 'ltr' ? (
									<div>
										I read, agree and consent to the processing of my personal data in accordance with the <p onClick={() => window.open(privacyPolicyLink, "_blank")}>Privacy Policy</p>
									</div>) : (<div>قرأت وأوافق على معالجة بياناتي الشخصية وفقًا <p onClick={() => window.open(privacyPolicyLink, "_blank")}>لسياسة الخصوصية</p></div>)}
							</div>
						</p>
					</div>

					<div className={Styles.form_item}>
						<p className={Styles.flex_ai_start}>
							<input
								type="checkbox"
								id="mailing"
								name="mailing"
								defaultChecked={true}
								disabled={loading}
							/>
							<label htmlFor="mailing" className={Styles.checkbox_label}>
								{t('forms:mailings')}
							</label>
						</p>
					</div>
					<div className={Styles.submit_container}>
						<input type="submit" value={t('forms:sendEmail')} disabled={loading} />
					</div>
				</form>

				<Snackbar anchorOrigin={alertSettings} open={open} autoHideDuration={5000} onClose={handleClose}>
					<Alert
						onClose={handleClose}
						severity={answer.status === 'Ok' ? 'success' : 'error'}
						variant={'filled'}
						sx={{ width: '100%' }}
					>
						{answer.text}
					</Alert>
				</Snackbar>
			</div>

			<div className={Styles.image_wrapper}>
				<img src={slideImg} alt="slide" className={Styles.slide_img} />
			</div>
		</section>
	)
}

export default TherapistForm
