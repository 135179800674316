import { Suspense } from 'react'

// Routing
import { Routes, Navigate, Route } from 'react-router-dom'
// Components
import { Main } from './components'
import {
	ConsultFormPage,
	MainPage,
	PrivacyPolicyPage,
	TermsOfUsePage,
	TherapistFormPage,
	ThankYouPage,
	JoinOurNetworkPage,
} from './pages'

const App = () => {
	return (
		<Suspense fallback={'loading'}>
			<Routes>
				<Route path="/" element={<Main header={true} consultButton={true} footer={true} />}>
					<Route index element={<MainPage />} />
				</Route>

				<Route path="/join-us" element={<Main header={true} consultButton={true} footer={false} />}>
					<Route index element={<TherapistFormPage />} />
				</Route>

				<Route path="/privacy-policy" element={<Main header={false} consultButton={true} footer={false} />}>
					<Route index element={<PrivacyPolicyPage />} />
				</Route>

				<Route path="/terms-of-use" element={<Main header={false} consultButton={true} footer={false} />}>
					<Route index element={<TermsOfUsePage />} />
				</Route>

				<Route path="/thank-you" element={<Main header={true} consultButton={true} footer={true} />}>
					<Route index element={<ThankYouPage />} />
				</Route>

				<Route path="/join-our-network" element={<Main header={true} consultButton={false} footer={true} />}>
					<Route index element={<JoinOurNetworkPage />} />
				</Route>

				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Suspense>
	)
}

export default App
