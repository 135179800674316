import { Link } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//Traslation
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import PreConsultationPopup from '../PreConsultationPopup'

const InviteToFillForm = (props) => {
	const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

  const [isOpenPreConsultation, setIsOpenPreConsultation] = useState(false);

  const handlePreOpenConsultation = () => setIsOpenPreConsultation(true);
  const handlePreCloseConsultation = () => setIsOpenPreConsultation(false);


	return (
		<section className={Styles.wrapper}>
			<div className={Styles.card} dir={textDirection}>
				<h1 className={`${Styles.title} ${!props.getConsultation ? Styles.change_width : null}`}>
					{props.getConsultation ? `${t('joinParent.fillFormParentTitle')}` : `${t('joinTherapist.fillFormTherapistTitle')}`}
				</h1>
				<p className={Styles.description}>
					{props.getConsultation ? `${t('joinParent.fillFormParentDescr')}` : `${t('joinTherapist.fillFormTherapistDescr')}`}
				</p>

				{props.getConsultation ? (
					<button className={Styles.consult_btn} onClick={handlePreOpenConsultation}>
						{t('buttons.freeConsultation')}
					</button>
				) : (
					<Link to="/join-us" className={Styles.consult_btn}>
						{t('buttons.becomeTherapist')}
					</Link>
				)}

			</div>
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={isOpenPreConsultation}
      />
		</section>
	)
}

export default InviteToFillForm
