//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from 'react-i18next'

const Growth = ({setIsOpenConsultation}) => {
  const { t, i18n } = useTranslation(['common'])
	const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

  const handleOpenConsultation = () => setIsOpenConsultation(true);

  return (
    <section className={Styles.wrapper} id={"about"}>
      <div className={Styles.content_wrapper} dir={textDirection}>
        <h1 className={Styles.title}>{t('growth.specialistSupportTitle')}</h1>
        <p className={Styles.description}>
          {t('growth.specialistSupportDescr')}
        </p>
        <p style={{fontSize: '19px'}} className={Styles.description}>
          {t('growth.specialistSupportDescrSecond')}
        </p>
        <button button onClick={handleOpenConsultation} className={Styles.consult_btn}>
            {t('buttons.freeConsultation')}
        </button>
      </div>
      <div className={Styles.image} />
    </section>
  );
};

export default Growth;
