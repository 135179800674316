// Components
import {
  ChooseSpecialist,
  CompanyCooperation,
  Experts,
  InviteToFillForm,
  Growth,
  MeetAdvisor,
  Mission,
  PersonalAssistent,
  StartTherapy,
  Tariffs,
} from "../components";

import { useState } from "react";
import PreConsultationPopup from "../components/PreConsultationPopup";

const MainPage = () => {
  const [isOpenPreConsultation, setIsOpenPreConsultation] = useState(false);

  const handlePreCloseConsultation = () => setIsOpenPreConsultation(false);

  return (
    <>
      <Growth setIsOpenConsultation={setIsOpenPreConsultation} />
      <Mission />
      <MeetAdvisor />
      <ChooseSpecialist />
      <StartTherapy />
      <InviteToFillForm getConsultation={true} />
      <PersonalAssistent />
      <div style={{ width: "100%", background: "#F8F8F8" }}>
        <Experts />
        <CompanyCooperation />
      </div>
      {/* <Tariffs /> */}
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={isOpenPreConsultation}
      />
    </>
  );
};

export default MainPage;
